<template>
	<div class="locale">
		<button
			v-for="entry in languages"
			:key="entry.title"
			@click="changeLocale(entry.language)"
		>
			<flag :iso="entry.flag" :squared="false" />
		</button>
	</div>
</template>

<script>
export default {
	methods: {
		changeLocale(locale) {
			if (this.$i18n.locale !== locale) {
				this.$i18n.locale = locale;
				const to = this.$router.resolve({ params: { locale } });
				this.$router.push(to.location);
			}
		},
	},
	data() {
		return {
			languages: [
				{ flag: 'no', language: 'no' },
				{ flag: 'gb', language: 'en' },
				{ flag: 'pl', language: 'pl' },
			],
		};
	},
};
</script>

<style lang="scss" scoped>
.locale {
	button {
		padding: 4px 6px 4px 6px;
		margin-left: 10px;
		cursor: pointer;
	}
}
</style>
