<template>
	<div>
		<v-app-bar app color="blue-grey darken-4" height="100" flat hide-on-scroll>
			<v-container class="d-flex align-center">
				<v-app-bar-nav-icon
					class="white--text"
					x-large
					@click.stop="drawer = !drawer"
				/>
				<v-row
					no-gutters
					class="d-flex grow-1 justify-center ml-10 align-center"
				>
					<picture>
						<source
							srcset="../assets/logo-tj.webp"
							type="image/webp"
							class="logo"
						/>
						<source
							srcset="../assets/logo-tj.png"
							type="image/png"
							class="logo"
						/>
						<img
							src="../assets/logo-tj.png"
							alt="Tryggjobb.no Logo"
							class="logo"
						/>
					</picture>

					<h2 class="text-h5 pl-1 font-weight-light d-none d-sm-flex">
						<a
							href="/"
							class="white--text text-uppercase"
							style="text-decoration:none;"
							>{{ $t('layout.title') }}</a
						>
					</h2>
				</v-row>

				<Locale />
			</v-container>
		</v-app-bar>
		<v-navigation-drawer
			v-model="drawer"
			absolute
			width="320"
			temporary
			class="blue-grey darken-4"
			dark
		>
			<v-list nav>
				<v-list-item-group active-class="white--text">
					<v-list-item two-line exact :to="$i18nRoute({ name: 'Home' })">
						<h4 class="text-h6 font-weight-light">
							{{ $t('layout.home') }}
						</h4>
					</v-list-item>
					<v-list-item
						two-line
						exact
						:to="$i18nRoute({ name: 'Typeopplaering' })"
					>
						<h4 class="text-h6 font-weight-light white--text">
							{{ $t('layout.typeopplaering') }}
						</h4>
					</v-list-item>
					<v-list-item
						two-line
						exact
						:to="$i18nRoute({ name: 'Dagligkontroll' })"
					>
						<h4 class="text-h6 font-weight-light white--text">
							{{ $t('layout.dagligKontroll') }}
						</h4>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>

<script>
import Locale from '@/components/Locale.vue';
export default {
	data: () => ({
		drawer: false,
	}),
	components: {
		Locale,
	},
};
</script>

<style scoped>
.logo {
	max-width: 50px;
	object-fit: contain;
}
</style>
