<template>
	<v-app>
		<HeaderComponent />
		<router-view></router-view>
		<FooterComponent />
	</v-app>
</template>

<script>
import HeaderComponent from '@/components/Header.vue';
import FooterComponent from '@/components/Footer.vue';
export default {
	name: 'App',
	components: {
		HeaderComponent,
		FooterComponent,
	},
};
</script>

<style lang="scss">
.container {
	max-width: 1120px;
	padding: 0;
}
</style>
