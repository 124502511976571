import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import FlagIcon from 'vue-flag-icon';
import i18n from './i18n';
import { Trans } from './plugins/Translation';

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans);
Vue.use(FlagIcon);

Vue.config.productionTip = false;

new Vue({
	router,
	vuetify,
	i18n,
	render: (h) => h(App),
}).$mount('#app');
