import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '../i18n';

function load(component) {
	return () => import(`@/views/${component}.vue`);
}

Vue.use(VueRouter);

const routes = [
	{
		path: '',
		redirect: '/no',
	},
	{
		path: '/:locale',
		component: {
			template: '<router-view></router-view>',
		},
		beforeEnter: (to, from, next) => {
			const locale = to.params.locale;
			const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(
				','
			);
			if (!supported_locales.includes(locale)) return next('no');
			if (i18n.locale !== locale) {
				i18n.locale = locale;
			}
			return next();
		},
		children: [
			{
				path: '',
				name: 'Home',
				component: () =>
					import(/* webpackChunkName: "[request]" */ `@/views/Home.vue`),
			},
			{
				path: 'typeopplaering',
				name: 'Typeopplaering',
				component: () =>
					import(
						/* webpackChunkName: "[request]" */ `@/views/Typeopplaering.vue`
					),
			},
			{
				path: 'dagligkontroll',
				name: 'Dagligkontroll',
				component: () =>
					import(
						/* webpackChunkName: "[request]" */ `@/views/Dagligkontroll.vue`
					),
			},
			{
				path: '*',
				component: () =>
					import(/* webpackChunkName: "[request]" */ `@/views/404.vue`),
			},
		],
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
